<template>
  <div
    :key="key"
    :class="$style.blockInput"
  >
    <input
      v-for="item in 6"
      ref="item"
      :key="item"
      v-model="items[item - 1]"
      :class="$style.item"
      :maxlength="1"
      :disabled="disabled"
      :inputmode="mode"
      @paste="paste"
      @keydown="eraseInput(item - 1, $event)"
      @input="input(item - 1, $event)"
    >
  </div>
</template>

<script>
export default {
  name: 'BlockInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      items: [],
      key: Date.now(),
    };
  },
  watch: {
    items(value) {
      return this.$emit('input', value.join(''));
    },
    value(value) {
      if (value === null) {
        this.items = [];
        this.setFocusZeroItem();
      }
    },
  },
  mounted() {
    return this.setFocusZeroItem();
  },
  methods: {
    setFocusZeroItem() {
      return this.$nextTick(() => this.$refs.item?.[0]?.focus());
    },
    paste({ clipboardData }) {
      const result = clipboardData.getData('text').slice(0, 6).split('');

      this.setValue(result);
    },
    setValue(value) {
      this.items = value;
      this.key = Date.now();

      setTimeout(() => {
        const item = this.$refs.item[value.length];

        return item ? item.focus() : this.$refs.item[5].focus();
      }, 0);
    },
    input(index, { data, target }) {
      const { value } = target;

      if (value) {
        if (value.length === 2) {
          this.items[index] = data;
        }
        if (value.length > 2) {
          const result = data.slice(0, 6).split('');
          this.setValue(result);
          return;
        }
        this.$refs.item[index + 1]?.focus();
      }
    },
    eraseInput(index, { keyCode }) {
      if (keyCode === 8) {
        const { value } = this.$refs.item[index];

        if (!value) {
          this.$refs.item[index - 1]?.focus();
        }
      }
    },
  },
};
</script>

<style lang="scss" module>
.blockInput {
  position: relative;
  display: flex;
  justify-content: center;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-decor, #dbdbdb);
    border-radius: 3px;
    padding: 10px;
    width: 42px;
    height: 42px;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    transition: var(--transition, 0.2s ease);

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      outline: none;
      border: 1px solid var(--color-decor-secondary, #b5b5b5);
    }
  }
}
</style>
