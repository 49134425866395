<template>
  <div
    :class="$style.verifyEmail"
    class="modal-card"
  >
    <div class="modal-card-head">
      <p class="modal-card-title">
        Verify your email
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <b-field
          :type="codeStatus.error"
          :message="codeStatus.message"
          :class="$style.description"
        >
          Enter 6 digit code that has been sent to
          <span>
            {{ email }}
          </span>
        </b-field>

        <b-field>
          <Code
            v-model="form.code"
            mode="numeric"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="serverError"
          class="info"
        >
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="loading"
        >
          OK
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="$emit('close')"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import Code from '@/components/code.vue';
import codeMixin from '@/views/settings/mixins/codeMixin.js';
import { required, numeric } from '@/utils/validators.js';
import { useSettingsStore } from '@/stores/index.js';

export default {
  name: 'VerifyEmailModal',
  components: {
    Code,
  },

  mixins: [codeMixin],
  props: {
    email: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      serverError: null,
      loading: false,
      form: {
        email: this.email,
        code: null,
      },
    };
  },

  validations: {
    form: {
      code: {
        required,
        length: (value) => value?.length === 6,
        numeric,
      },
    },
  },

  methods: {
    ...mapActions(useSettingsStore, ['updateEmailConfirm', 'userData']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.serverError = null;
      this.loading = true;

      const { error } = await this.updateEmailConfirm(this.form);

      this.loading = false;

      if (error) {
        this.serverError = error;
        return this.serverError;
      }

      this.$emit('close');

      return await this.userData();
    },
  },
};
</script>

<style lang="scss" module>
.verifyEmail {
  position: relative;

  .description {
    margin-bottom: 20px;
    text-align: center;
    color: #3e3e3e;

    span {
      font-weight: 700;
    }
  }
}
</style>
