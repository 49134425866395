<template>
  <div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">
        Change domain
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <div :class="$style.description">
          Dedicated domain for your forms. If you change it, previously referenced form links with
          this domain will no longer work.
        </div>
        <b-field :type="domainStatus.error" :message="domainStatus.message">
          <div :class="$style.controls">
            <b-input
              ref="firstField"
              v-model="form.domain"
              placeholder="Enter domain"
              :disabled="loading"
              maxlength="150"
              :has-counter="false"
              :class="$style.input"
            />
            <div>{{ form.userFormsSuffix }}</div>
          </div>
        </b-field>

        <b-field v-if="serverError" class="info">
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button :loading="loading" type="is-primary" native-type="submit">
          Save
        </b-button>

        <b-button :disabled="loading" type="is-light" @click="$emit('close')">
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import formsDomainMixin from '@/views/settings/mixins/formsDomainMixin.js';
import { required } from '@/utils/validators.js';
import { useSettingsStore } from '@/stores/index.js';
import { userFormsUrl } from '@/utils/url-manager.js';

export default {
  name: 'ChangeDomain',
  mixins: [autoFocus, formsDomainMixin],
  props: {
    domain: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serverError: null,
      loading: false,
      alreadyExistsError: false,
      form: {
        domain: this.domain,
        userFormsSuffix: new URL(userFormsUrl).host.replace('{0}', ''),
      },
    };
  },
  validations: {
    form: {
      domain: {
        required,
        domain: (value) => /^[\w-]*$/.test(value),
      },
    },
  },
  watch: {
    'form.domain': function () {
      this.alreadyExistsError = false;
    },
  },
  methods: {
    ...mapActions(useSettingsStore, ['updateFormsDomain', 'readFormsDomain']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.setError(null);
      this.loading = true;

      const { error } = await this.updateFormsDomain({ domain: this.form.domain.slice(0, 150) });

      this.loading = false;

      if (error) return this.setError(error);

      this.$emit('close');

      return this.readFormsDomain();
    },

    setError(error) {
      if (error?.code === 'USR-001') {
        this.alreadyExistsError = true;
        this.$refs.firstField?.focus();
      } else {
        this.serverError = error;
      }
    },
  },
};
</script>

<style lang="scss" module>
.description {
  margin-bottom: 15px;
}

.controls {
  display: flex;
  align-items: center;

  .input {
    flex-grow: 1;
    margin-right: 5px;
  }
}
</style>
