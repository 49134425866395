<template>
  <div
    :class="$style.deleteAccount"
    class="modal-card"
  >
    <div
      class="modal-card-head"
      :class="$style.title"
    >
      <p class="modal-card-title">
        Delete account
      </p>
    </div>

    <form @submit.prevent="submit">
      <div
        :class="$style.content"
        class="modal-card-body"
      >
        <b-field
          :class="$style.info"
          class="info"
        >
          <div class="error">
            There's no undo button, and we can't get your data back for you after.
            This affects Plumsail Documents, Forms, and Actions.
          </div>
        </b-field>

        <b-field
          label="Some feedback about us?"
          :type="feedbackStatus.error"
          :message="feedbackStatus.message"
        >
          <b-input
            ref="firstField"
            v-model="form.feedback"
            type="textarea"
            :disabled="loading"
          />
        </b-field>

        <b-field
          label="Enter your password"
          :type="passwordStatus.error || serverError && 'is-danger'"
          :message="passwordStatus.message"
        >
          <b-input
            v-model="form.password"
            type="password"
            placeholder="Current password"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="serverError"
          class="info"
        >
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          :loading="loading"
          type="is-danger"
          native-type="submit"
        >
          Delete forever
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="$emit('close')"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import feedbackMixin from '@/views/settings/mixins/feedbackMixin.js';
import passwordMixin from '@/views/settings/mixins/passwordMixin.js';
import { required } from '@/utils/validators.js';
import { useSettingsStore } from '@/stores/index.js';

export default {
  name: 'DeleteAccountModal',
  mixins: [autoFocus, feedbackMixin, passwordMixin],
  data() {
    return {
      serverError: null,
      loading: false,
      form: {
        feedback: null,
        password: null,
      },
    };
  },
  validations: {
    form: {
      feedback: {
        required,
      },
      password: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(useSettingsStore, ['deleteAccount', 'logoutAccount']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.serverError = null;
      this.loading = true;

      const { error } = await this.deleteAccount(this.form);

      this.loading = false;

      if (error) {
        this.serverError = error;
        return this.serverError;
      }

      return this.logoutAccount();
    },
  },
};
</script>

<style lang="scss" module>
.deleteAccount {
  .title {
    padding-bottom: 10px;
  }

  .content {
    padding-top: 0;
  }
}
</style>
