<template>
  <div :class="$style.settings">
    <settings-title :value="'Account settings'" />

    <div :class="$style.field">
      <div :class="$style.subtitle">
        Name
      </div>

      <div :class="$style.input">
        <div :class="$style.text">
          {{ getProfile.name }}
        </div>

        <div :class="$style.icon" @click="changeName">
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </div>
    </div>

    <div :class="$style.field">
      <div :class="$style.subtitle">
        Email
      </div>

      <div :class="$style.input">
        <div :class="$style.text">
          {{ getProfile.email }}
        </div>

        <div :class="$style.icon" @click="changeEmail">
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </div>
    </div>

    <div v-if="formsDomain" :class="$style.field">
      <div :class="$style.subtitle">
        Domain
      </div>

      <div :class="$style.input">
        <div :class="$style.text">
          {{ formBaseUrl }}
        </div>

        <div :class="$style.icon" @click="changeFormsDomain">
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </div>
      <div :class="$style.description">
        Dedicated domain for your forms. If you change it, previously referenced form links with
        this domain will no longer work.
      </div>
    </div>

    <div :class="$style['field--security']">
      <div :class="$style.subtitle">
        Security
      </div>
      <div :class="$style.security">
        <bulma-switch
          v-model="twoFaEnabled"
          :show-captions="false"
          :label="'Two-factor authentication'"
        />
        <div
          v-show="twoFaEnabled === 'on'"
          :class="[$style.icon, $style.securityIcon]"
          @click="twoFaEnable"
        >
          <font-awesome-icon :icon="['fal', 'pencil']" />
        </div>
      </div>
    </div>

    <div :class="$style.controls">
      <b-button :class="$style.button" @click="changePassword">
        <font-awesome-icon :class="$style.btnIcon" :icon="['fal', 'key']" />
        Change password
      </b-button>

      <router-link :class="[$style.button, 'button']" to="/account/audit-log">
        <span>
          <font-awesome-icon :class="$style.btnIcon" :icon="['fal', 'clipboard-list']" />
          Audit log
        </span>
      </router-link>

      <b-button :class="$style.button" @click="removeAccount">
        <font-awesome-icon :class="$style.btnIcon" :icon="['fal', 'user-xmark']" />
        Delete account
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import DeleteAccountModal from '@/views/settings/modals/delete-account-modal.vue';
import ChangeNameModal from '@/views/settings/modals/change-name-modal.vue';
import ChangeEmailModal from '@/views/settings/modals/change-email-modal.vue';
import ChangeFormsDomainModal from '@/views/settings/modals/change-forms-domain-modal.vue';
import SettingsTitle from '@/views/settings/components/title.vue';
import TwoFaAuthModal from '@/views/settings/modals/two-fa-auth-modal.vue';
import { BulmaSwitchComponent } from '@/components/basic/index.js';
import { useSettingsStore } from '@/stores/index.js';
import { authEndpoint, userFormsUrl } from '@/utils/url-manager.js';
import { stringFormat } from '@/utils/index.js';

export default {
  name: 'AccountSettings',
  components: {
    SettingsTitle,
    'bulma-switch': BulmaSwitchComponent,
  },

  data() {
    return {
      optionsModal: {
        clickToClose: false,
        classes: 'modal',
        width: '440px',
        height: 'auto',
        adaptive: true,
        draggable: '.modal-card-head',
      },
      twoFaEnabled: 'on',
    };
  },

  computed: {
    ...mapState(useSettingsStore, ['getProfile', 'formsDomain']),

    twoFaCurrentState() {
      return this.getProfile.twoFactorEnabled ? 'on' : 'off';
    },
    formBaseUrl() {
      return new URL(stringFormat(userFormsUrl, this.formsDomain)).host;
    },
  },

  watch: {
    async twoFaEnabled(newVal) {
      if (newVal === this.twoFaCurrentState) {
        return;
      }

      if (newVal === 'on') {
        await this.twoFaEnable();
      } else {
        await this.twoFaDisable();
      }
    },
  },

  mounted() {
    this.twoFaEnabled = this.twoFaCurrentState;
  },

  created() {
    this.readFormsDomain();
  },

  beforeDestroy() {
    this.modal?.$children[0]?.$emit('close');
  },

  methods: {
    ...mapActions(useSettingsStore, ['readFormsDomain', 'twoFaLink', 'disable2Fa']),

    changeName() {
      this.modal = this.$modal.show(
        ChangeNameModal,
        { name: this.getProfile.name },
        this.optionsModal,
      );
    },

    changeEmail() {
      this.modal = this.$modal.show(
        ChangeEmailModal,
        { email: this.getProfile.email },
        this.optionsModal,
      );
    },

    changeFormsDomain() {
      this.modal = this.$modal.show(
        ChangeFormsDomainModal,
        { domain: String(this.formsDomain) },
        this.optionsModal,
      );
    },

    removeAccount() {
      this.modal = this.$modal.show(DeleteAccountModal, null, this.optionsModal);
    },

    changePassword() {
      window.location.href = `${authEndpoint}account/change-password`;
    },

    async twoFaEnable() {
      const resp = await this.twoFaLink();
      this.modal = this.$modal.show(
        TwoFaAuthModal,
        {
          link: resp.data.link,
          secureKey: resp.data.key,
          cancellation: () => {
            this.twoFaEnabled = this.twoFaCurrentState;
          },
        },
        this.optionsModal,
      );
    },

    async twoFaDisable() {
      this.modal = this.$modal.show(
        TwoFaAuthModal,
        {
          action: 'disable',
          cancellation: () => {
            this.twoFaEnabled = this.twoFaCurrentState;
          },
        },
        {
          clickToClose: false,
          classes: 'modal',
          width: '392px',
          height: '495px',
        },
      );
    },
  },
};
</script>

<style lang="scss" module>
.settings {
  .title {
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    color: var(--color-main);
  }

  .subtitle {
    margin-bottom: 0;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-secondary);
  }

  .field {
    margin-bottom: 17px;

    &--security {
      margin-top: 6px;
      margin-bottom: 11px;
    }
  }

  .input {
    display: flex;
    align-items: center;

    .text {
      margin-right: 10px;
      word-break: break-all;
    }
  }

  .icon {
    transition-duration: var(--transition-duration);
    cursor: pointer;

    &:hover {
      transform: scale(1.4);
    }
  }

  .description {
    margin-top: 1px;
    max-width: 390px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #7a7a7a;
  }

  .controls {
    display: flex;
    gap: 6px;
    margin-bottom: 20px;

    .button {
      &:hover {
        color: var(--color-main);
      }
    }

    .secutityBtn {
      height: 30px;
      margin: 5px 5px 0 0;

      &:hover {
        color: var(--color-main);
      }
    }
  }

  .security {
    display: flex;
  }

  .securityIcon {
    margin-top: 8px;
    margin-left: 8px;
  }

  .btnIcon {
    margin-right: 4px;
    margin-left: -4px;
  }
}
</style>
