<template>
  <div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">
        Change name
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <b-field
          :type="nameStatus.error"
          :message="nameStatus.message"
        >
          <b-input
            ref="firstField"
            v-model.trim="form.name"
            placeholder="Enter name"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="serverError"
          class="info"
        >
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          :loading="loading"
          type="is-primary"
          native-type="submit"
        >
          Save
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="$emit('close')"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import nameMixin from '@/views/settings/mixins/nameMixin.js';
import { required } from '@/utils/validators.js';
import { useSettingsStore } from '@/stores/index.js';

export default {
  name: 'ChangeName',
  mixins: [autoFocus, nameMixin],
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serverError: null,
      loading: false,
      form: {
        name: this.name,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },

  methods: {
    ...mapActions(useSettingsStore, ['userData', 'updateUsername']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.serverError = null;
      this.loading = true;

      const { error } = await this.updateUsername(this.form);

      this.loading = false;

      if (error) {
        this.serverError = error;
        return this.serverError;
      }

      this.$emit('close');

      return this.userData();
    },
  },
};
</script>
