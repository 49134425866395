export default {
  computed: {
    feedbackStatus() {
      const error = this.$v.form.feedback.$error;

      return {
        error: error ? 'is-danger' : '',
        message: error ? 'Feedback field is required' : '',
      };
    },
  },
};
