export default {
  computed: {
    passwordStatus() {
      const error = this.$v.form.password.$error;

      return {
        error: error ? 'is-danger' : '',
        message: error ? 'Password field is required' : '',
      };
    },
  },
};
