<template>
  <div class="modal-card">
    <div class="modal-card-head">
      <p class="modal-card-title">
        Change email
      </p>
    </div>

    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <b-field
          :type="emailStatus.error"
          :message="emailStatus.message"
        >
          <b-input
            ref="firstField"
            v-model.trim="form.email"
            placeholder="Enter email"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="serverError"
          class="info"
        >
          <div class="error">
            {{ serverError }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          :loading="loading"
          type="is-primary"
          native-type="submit"
        >
          Next
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="$emit('close')"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import autoFocus from '@/views/settings/mixins/autoFocus.js';
import emailMixin from '@/views/settings/mixins/emailMixin.js';
import { required, email } from '@/utils/validators.js';
import VerifyEmailModal from '@/views/settings/modals/verify-email-modal.vue';
import { useSettingsStore } from '@/stores/index.js';

export default {
  name: 'ChangeEmail',
  mixins: [autoFocus, emailMixin],
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      serverError: null,
      loading: false,
      form: {
        email: this.email,
      },
      optionsModal: {
        clickToClose: false,
        classes: 'modal',
        width: '440px',
        height: 'auto',
        adaptive: true,
        draggable: '.modal-card-head',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    ...mapActions(useSettingsStore, ['updateEmail']),

    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.serverError = null;
      this.loading = true;

      const { error } = await this.updateEmail(this.form);

      this.loading = false;

      if (error) {
        this.serverError = error;
        return this.serverError;
      }

      this.$emit('close');

      // Wait for completely close modal
      return setTimeout(() => this.verifyEmail(), 150);
    },

    verifyEmail() {
      this.modal = this.$modal.show(
        VerifyEmailModal,
        { email: this.form.email },
        this.optionsModal,
      );
    },
  },
};
</script>
