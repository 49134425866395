<template>
  <div
    :class="$style.verifyEmail"
    class="modal-card"
  >
    <div class="modal-card-head">
      <p class="modal-card-title">
        Two-factor Authentication
      </p>
    </div>
    <form @submit.prevent="submit">
      <div class="modal-card-body">
        <div v-show="isEnable">
          <p>Scan the QR code with an authenticator app on your phone.</p>
          <div :class="$style.qr">
            <qrcode-vue :value="value" />
          </div>
        </div>

        <b-field v-if="key">
          <div>Or set it up with a security key: <span>{{ key }}</span></div>
        </b-field>

        <b-field>
          <div v-if="isEnable">
            Then enter the code from your authenticator app.
          </div>
          <div v-else>
            To confirm disabling two-factor authentication,
            enter the code generated by your authenticator
            application
          </div>
        </b-field>

        <b-field :class="$style.code">
          <Code
            ref="firstField"
            v-model="form.code"
            mode="numeric"
            :disabled="loading"
          />
        </b-field>

        <b-field
          v-if="errorMsg"
          class="info"
        >
          <div :class="$style['error-msg']">
            {{ errorMsg }}
          </div>
        </b-field>
      </div>

      <div class="modal-card-foot">
        <b-button
          type="is-primary"
          native-type="submit"
          :loading="loading"
          :class="$style['ok-btn']"
        >
          OK
        </b-button>

        <b-button
          :disabled="loading"
          type="is-light"
          @click="close"
        >
          Close
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from 'pinia';
import QrcodeVue from 'qrcode.vue';
import Code from '@/components/code.vue';
import codeMixin from '@/views/settings/mixins/codeMixin.js';
import { required, numeric } from '@/utils/validators.js';
import { useSettingsStore } from '@/stores/index.js';

export default {
  name: 'TwoFaModal',
  components: {
    Code,
    QrcodeVue,
  },

  mixins: [
    codeMixin,
  ],
  props: {
    link: {
      type: String,
      default: '',
    },
    secureKey: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: 'enable',
    },
    cancellation: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      key: this.secureKey,
      value: this.link,
      errorMsg: null,
      loading: false,
      form: {
        code: null,
      },
    };
  },

  validations: {
    form: {
      code: {
        required,
        length: (value) => value?.length === 6,
        numeric,
      },
    },
  },

  computed: {
    isEnable() {
      return this.action === 'enable';
    },
  },

  watch: {
    'form.code': function () {
      if (this.form.code && this.form.code.length === 6) {
        this.submit();
      }
    },
    'codeStatus.message': function () {
      this.errorMsg = this.codeStatus.message;
    },
  },

  methods: {
    ...mapActions(useSettingsStore, ['userData', 'enable2Fa', 'disable2Fa']),
    close() {
      if (this.cancellation) {
        this.cancellation();
      }
      this.$emit('close');
    },
    async submit() {
      this.$v.$touch();

      if (this.$v.$invalid) return false;

      this.errorMsg = null;
      this.loading = true;

      const { error } = this.isEnable
        ? await this.enable2Fa(this.form.code)
        : await this.disable2Fa(this.form.code);

      this.loading = false;

      if (error) {
        this.errorMsg = error;
        this.form.code = null;
        this.$v.$reset();
        return this.errorMsg;
      }

      this.$emit('close');

      return await this.userData();
    },
  },
};
</script>

<style lang="scss" module>
.error-msg{
  text-align: center;
  color: #C1390E;
}
.code{
  margin-bottom: 20px;
  margin-top: 18px;
}

.ok-btn{
  min-width: 53px !important;
}
.verifyEmail {
  position: relative;

  .description {
    margin-bottom: 20px;
    text-align: center;
    color: #3e3e3e;

    span {
      font-weight: 700;
    }
  }
}

.qr {
  text-align: center;
  margin: 20px;
}
</style>
