export default {
  computed: {
    codeStatus() {
      const error = this.$v.form.code.$error;
      const requiredError = !this.$v.form.code.required;
      const lengthError = !this.$v.form.code.length;
      const numericError = !this.$v.form.code.numeric;
      const requiredMessage = (error && requiredError) ? 'Code field is required' : '';
      const lengthMessage = (error && lengthError) ? 'Code field length must be 6 digits' : '';
      const numericMessage = (error && numericError) ? 'Code field only accept numbers' : '';

      return {
        error: error ? 'is-danger' : '',
        message: requiredMessage || lengthMessage || numericMessage,
      };
    },
  },
};
