export default {
  computed: {
    domainStatus() {
      const error = this.$v.form.domain.$error || this.alreadyExistsError;
      const requiredError = !this.$v.form.domain.required;
      const domainError = !this.$v.form.domain.domain;
      const requiredMessage = (error && requiredError) ? 'This field is required ' : null;
      const domainMessage = (error && domainError) ? 'The domain must contain only letters, numbers, and hyphens or underscores' : null;
      const alreadyExistsErrorMessage = this.alreadyExistsError ? 'This domain already exists' : null;

      return {
        error: error ? 'is-danger' : '',
        message: requiredMessage ?? domainMessage ?? alreadyExistsErrorMessage,
      };
    },
  },
};
